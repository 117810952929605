import { defer } from "react-router";
import { LeadsService } from "../../services/Leads";
import { LeadsParams, PlatformInteface } from "./interfaces";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function leadsLoader({
  getToken,
  request,
  pageName,
}: CustomLoaderFunctionArgs) {
  const url = new URL(request.url);
  const token = getToken();

  const filterData = JSON.parse(
    window?.localStorage?.getItem("filters") || "{}"
  );
  const leads = filterData?.leads;

  let params: LeadsParams = {
    page: url.searchParams.has("page")
      ? Number(url.searchParams.get("page"))
      : 1,
    limit: 10,
    searchQuery: url.searchParams.get("searchText") ?? "",
    searchType: url.searchParams.get("searchType") ?? "",
    filters: {},
    statusText: url.searchParams.getAll("statusText"),
    dateFilter: url.searchParams.get("dateType") ?? "updated_at",
    from: url.searchParams.has("fromDate")
      ? new Date(url.searchParams.get("fromDate") as string)
      : null,
    to: url.searchParams.has("toDate")
      ? new Date(url.searchParams.get("toDate") as string)
      : null,
    constitution: leads?.constitution?.value || "",
    schemes: leads?.schemes?.value || "",
    segments: leads?.segments?.value || "",
    sourceEntityIDs:
      leads?.platform?.map((source: PlatformInteface) => source?.value) || [],
    dsaIDs: leads?.agents?.map((agent: any) => agent?.value) ?? [],
  };

  return defer({
    storedFilters: leads,
    response: LeadsService.getUsersList({ ...params, token, pageName }),
  });
}
