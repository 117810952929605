import { useNavigate } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { addHours, addMinutes, format, formatDistance } from "date-fns";

import { NotesProps, NotesType } from "./interface";
import Button from "../../components/atomic/Button";
import { Heading } from "../../components/atomic/Heading";

export default function NotesTab(props: NotesProps) {
  const { href, notes = [], whiteLabelStyle = {}, navigate } = props;

  const refresh = () => {
    navigate(href, { replace: true });
  };

  return (
    <div className="px-4">
      <div className="flex items-center">
        <Heading
          name="Notes"
          whiteLabelStyle={whiteLabelStyle}
        />
        {!!href && (
          <Button
            outline
            onClick={refresh}
            title="Refresh Bank Statements"
            className="flex items-center text-xs text-gray-500 ml-4 !border-0 !p-1"
          >
            <ArrowPathIcon className="w-4 h-4 text-inherit" />
          </Button>
        )}
      </div>
      <div className="mt-8">
        {notes?.map((note: NotesType, indx: number) => {
          let createdAt = addHours(new Date(note.createdAt), 5);
          createdAt = addMinutes(createdAt, 30);
          return (
            <div
              key={indx}
              className="border border-gray-200 p-4 rounded-lg mb-2"
            >
              <div className="flex flex-col justify-center">
                <div className="notes-container">
                  <div
                    className="notes-content editor-style-container"
                    dangerouslySetInnerHTML={{ __html: note?.note }}
                  />
                </div>
                <div className="flex items-center mt-4">
                  <p className="text-xs text-gray-600">By: {note?.createdBy}</p>
                  <div className="mx-2 text-xs">|</div>
                  <p
                    className="text-xs text-gray-500"
                    title={format(createdAt, "dd MMM, yyyy - hh:mm aa")}
                  >
                    Created {formatDistance(new Date(), createdAt)} ago
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
