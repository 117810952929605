import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import NavbarMenuButton from "../../../shared/layout/navbar/NavbarMenuButton";
import { BreadcrumbsTwo } from "../Breadcrumbs";
import BreadcrumbsOne from "../Breadcrumbs/BreadcrumbsOne";
import { BreadCrumb } from "../Breadcrumbs/interface";
import { ReactNode } from "react";
import DropdownWithSearch from "../DropdownWithSearch";
interface IProps {
  title: string;
  children?: JSX.Element | Array<JSX.Element> | null;
  breadcrumbs: Array<BreadCrumb>;
  actionItems?: Array<any>;
  actionButtonText?: ReactNode;

  actionMode?: string;

  customActionElements?: ReactNode;
}
export default function PageHeader({
  title,
  children,
  breadcrumbs = [],
  actionItems = [],
  actionButtonText = "",
  actionMode = "default",
  customActionElements = null,
}: IProps) {
  return (
    <>
      <div className="shadow-sm md:shadow-none sticky md:relative top-14 md:top-0 z-[25] md:z-auto bg-white">
        {breadcrumbs?.length > 0 ? (
          <div className="pt-2 md:pt-4 pb-2 px-2 md:pr-2 md:pl-0">
            <BreadcrumbsOne breadcrumbs={breadcrumbs}></BreadcrumbsOne>
          </div>
        ) : (
          <div className="pt-4 pb-2"></div>
        )}
      </div>

      <div className="w-ful flex items-center justify-between">
        {title ? (
          <div className="sm:flex sm:items-end gap-2 sm:flex-row justify-start items-center sm:justify-between sm:w-full my-4 md:mt-4 md:mb-6 mx-3 sm:mx-0">
            {title ? (
              <h1 className="text-xl md:text-2xl pb-2 sm:pb-0 font-semibold text-gray-900">
                {title}
              </h1>
            ) : null}
            {children}
          </div>
        ) : null}
        {actionMode === "default" && actionItems?.length > 0 ? (
          <NavbarMenuButton
            menuItems={actionItems}
            menuDirection="right"
            srText="bulk actions"
            preContent=""
            styles={{
              button: "bg-indigo-600 rounded-md",
              container: "hidden md:block mb-6 min-w-max",
            }}
            buttonText={
              <div className="text-center font-medium text-white flex items-center justify-center">
                {actionButtonText || "Actions"}
                <ChevronDownIcon className="w-4 h-4 text-white ml-2" />
              </div>
            }
          />
        ) : null}

        {actionMode !== "default" ? <div>{customActionElements}</div> : null}
      </div>
    </>
  );
}
