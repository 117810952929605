import { isValid } from "date-fns";
import classNames from "classnames";
import format from "date-fns/format";
import { useLoaderData, useOutletContext } from "react-router-dom";

import { ListItem } from "../LeadDetails";
import { Get } from "../../shared/services/Fetch";
import Divider from "../../components/atomic/Divider";
import { PAGES_LIST } from "../../shared/utils/constants";
import GetAuthContext from "../../shared/context/AuthContext";
import { Accordion } from "../../components/atomic/Accordion";
import { formatNumberIntl, objectKeys } from "../../shared/utils/DateUtil";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

import {
  noHeadersResponse,
  getFormattedCurrency,
  calculateDisbursalAmount,
} from "../../shared/utils/Global";

export async function loanOfferLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/getLoanOffer?loanApplicationID=${loanID}`,
    getToken(),
    headers
  );
}

const LoanOfferDetails = ({ loanOfferDetails }: any) => {
  const { proposal = {} } = useOutletContext() as any;
  const { getPageConfig } = GetAuthContext();

  const insurancePremium =
    loanOfferDetails.premium +
      (loanOfferDetails.insuranceGST * loanOfferDetails.premium) / 100 || 0;

  let insurancePremiumText = "";
  if (!isNaN(insurancePremium)) {
    insurancePremiumText = `${getFormattedCurrency(insurancePremium)}`;
  }

  const insuranceProducts: any = loanOfferDetails?.insurance;

  const isEDI = loanOfferDetails?.tenureType === "EDI";

  const documentCharges = (loanOfferDetails?.documentCharges || 0) * 1.18 ?? 0;
  const pageConfig = getPageConfig();
  const disbursalAmount =
    pageConfig?.[PAGES_LIST.LOAN_APPLICATION_LOAN_OFFER_TAB]
      ?.disbursalAmountCalculationType === "custom"
      ? calculateDisbursalAmount(
          loanOfferDetails?.premium,
          loanOfferDetails?.gst,
          loanOfferDetails?.processingFee,
          loanOfferDetails?.amount
        )
      : loanOfferDetails?.disbursalAmount;

  let emi = "";
  let firstDate = "";
  let processingFee = "";

  const getFormattedProcessingFee = (fee: any) => {
    if (fee) {
      return `₹ ${formatNumberIntl(fee * (isEDI ? 1.18 : 1))}`;
    }
    return "";
  };

  const getFormattedDate = (dateStr: string) => {
    const dateSplit = dateStr?.split(" ");
    const date =
      dateSplit?.length && isValid(new Date(dateSplit[0]))
        ? new Date(dateSplit[0])
        : null;
    return date ? format(date, "MMM do, yyyy") : "";
  };

  if (isEDI) {
    emi = loanOfferDetails?.ediAmount
      ? formatNumberIntl(loanOfferDetails.ediAmount)
      : "";
    firstDate = loanOfferDetails?.firstEDIDate
      ? getFormattedDate(loanOfferDetails.firstEDIDate)
      : "";
    processingFee = getFormattedProcessingFee(loanOfferDetails?.processingFee);
  } else {
    const emiData = loanOfferDetails?.emis?.[0] || {};
    emi = emiData?.emiAmount ? formatNumberIntl(emiData.emiAmount) : "";
    firstDate = emiData?.emiDate
      ? format(new Date(emiData.emiDate), "MMM do, yyyy")
      : "";
    processingFee = getFormattedProcessingFee(loanOfferDetails?.processingFee);
  }

  return (
    <div className=" rounded-lg flex flex-wrap md:mt-8 pt-4 md:border md:border-gray-200">
      <div className="w-full md:w-2/3 py-4 md:py-8">
        <div className="mr-4 px-4 mb-8">
          {!!proposal?.loanTermDeviationStatus?.loanOfferWFSuccess && (
            <span className="text-xs bg-indigo-700 text-white rounded px-2.5 py-0.5 mb-1">
              Deviated Loan Offer
            </span>
          )}
          <p className="text-xs font-medium text-gray-500 mb-1 mt-2">
            Loan Amount
          </p>
          <p className="text-2xl md:text-4xl font-semibold">
            {"₹ " + loanOfferDetails?.amount
              ? formatNumberIntl(loanOfferDetails?.amount)
              : ""}
          </p>
        </div>
        <div className="px-4 flex">
          <ListItem
            className="mb-4 mr-12"
            label="Disbursal Amount"
            value={
              "₹ " + disbursalAmount ? formatNumberIntl(disbursalAmount) : ""
            }
          />
          <ListItem
            className=""
            label="Total Payable"
            value={
              "₹ " + loanOfferDetails?.totalPayableAmount
                ? formatNumberIntl(loanOfferDetails.totalPayableAmount)
                : ""
            }
          />
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap justify-start items-center md:block w-full md:w-1/3 py-4 px-4 md:px-8 border-t md:border-l md:border-t-0 border-gray-200">
        <ListItem
          label="Interest"
          className="w-1/2 md:w-auto mb-4 md:mr-0"
          value={loanOfferDetails.annualInterest + "%"}
        />
        <ListItem
          label="Tenure"
          className="w-1/2 md:w-auto mb-4 md:mr-0"
          value={loanOfferDetails.tenureMonths + (isEDI ? " Days" : " months")}
        />
        <ListItem
          value={emi}
          label={isEDI ? "EDI" : "EMI"}
          className="w-1/2 md:w-auto mb-4"
        />
      </div>

      <Divider className="my-4 w-full" />

      <div className="pb-0 px-4 md:px-8 w-full grid grid-cols-4">
        <div className="flex flex-col gap-2 mt-6 mr-2">
          <ListItem
            label="Taxes"
            value={loanOfferDetails.gst + "%"}
            className="w-1/2 md:w-auto mb-4 md:mb-0 md:mr-0"
          />
        </div>
        <div className="flex flex-col gap-2 mt-6 mr-2">
          <ListItem
            value={processingFee}
            className="w-1/2 md:w-auto mb-4 md:mb-0 md:mr-0"
            label={`Processing Fee ${isEDI ? "(inclusive of 18% GST)" : ""}`}
          />
        </div>
        <div className="flex flex-col gap-2 mt-6 mr-2">
          <ListItem
            label={`${isEDI ? "EDI" : "EMI"} Calculation Method`}
            className="w-1/2 md:w-auto capitalize  mb-4 md:mb-0 md:mr-0"
            value={loanOfferDetails?.emiCalculationMethod?.split("_").join(" ")}
          />
        </div>
        {!isEDI && (
          <div className="flex flex-col gap-2 mt-6 mr-2">
            <ListItem
              className="w-1/2 md:w-auto md:mr-0 mb-4 md:mb-0"
              label="Advance EMI Amount"
              value={
                "₹ " + loanOfferDetails?.advanceEMIAmount
                  ? formatNumberIntl(loanOfferDetails.advanceEMIAmount)
                  : ""
              }
            />
          </div>
        )}
      </div>
      <div className="pb-8 px-4 md:px-8 w-full grid grid-cols-4">
        <div className="flex flex-col gap-2 mt-8 mr-2">
          <ListItem
            value={firstDate}
            className="w-1/2 md:w-auto"
            label={`First ${isEDI ? "EDI" : "EMI"} Date`}
          />
        </div>
        {!isNaN(documentCharges) ? (
          <div className="flex flex-col gap-2 mt-8 mr-2">
            <ListItem
              className="w-1/2 md:w-auto md:mr-0 mb-8  md:mb-0"
              label="Document Charges (inclusive of applicable GST)"
              value={
                "₹ " + documentCharges ? formatNumberIntl(documentCharges) : ""
              }
            />
          </div>
        ) : // </div>
        null}
        {typeof insurancePremium !== "undefined" && (
          <div className="flex flex-col gap-2 mt-8 mr-2">
            <ListItem
              className="w-1/2 md:w-auto"
              label={`Insurance Premium Total (inclusive of ${loanOfferDetails.gst}% GST)`}
              value={<div>{insurancePremiumText}</div>}
            ></ListItem>
          </div>
        )}
        {insuranceProducts &&
          insuranceProducts?.length > 0 &&
          insuranceProducts?.map((insuranceProduct: LoanOfferInsurance) => (
            <div className="flex items-center  last:mr-0 mt-4 mr-2">
              <ListItem
                className="max-w-56"
                label={`Insurance Product : ${insuranceProduct.type}`}
                value={
                  <div>
                    {insuranceProduct.type &&
                      formatNumberIntl(
                        insuranceProduct.premium +
                          (loanOfferDetails.gst * insuranceProduct.premium) /
                            100 || 0
                      )}
                  </div>
                }
              ></ListItem>
            </div>
          ))}
      </div>
    </div>
  );
};

function LoanOffer() {
  const { data }: any = useLoaderData();
  const loanOfferDetails = data?.[0] ?? {};

  const outletContxt = useOutletContext() as any;
  const { whiteLabelStyle } = outletContxt ?? {};

  return (
    <div className="px-0 mb-12 md:mb-0 md:px-4 overflow-auto">
      <div className="hidden md:flex items-center text-base font-medium">
        <h3
          className={classNames(
            "text-sm text-gray-700 font-medium w-max border-b-2 py-2 pr-2",
            whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
          )}
        >
          Loan Offer
        </h3>
      </div>

      {objectKeys(loanOfferDetails).length > 0 ? (
        <>
          <div className="hidden md:block">
            <LoanOfferDetails loanOfferDetails={loanOfferDetails} />
          </div>
          <div className="block md:hidden">
            <Accordion
              title="Loan Offer"
              styles={{
                container: "block border border-gray-200 rounded-lg mb-4",
                button:
                  "px-4 py-2 flex items-center justify-between w-full text-sm font-medium",
              }}
              defaultOpen={true}
            >
              <LoanOfferDetails loanOfferDetails={loanOfferDetails} />
            </Accordion>
          </div>
        </>
      ) : (
        <div className="text-sm mt-6">
          <p>Loan Offer will show up here.</p>
        </div>
      )}
    </div>
  );
}

export default LoanOffer;
